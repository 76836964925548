<template>
  <div>
    <!-- <b-btn>add request</b-btn> -->

    <general-table
      :api-url="APIURL"
      :add-type="addType"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :selectable="false"
      :columns="filteredColumns"
      :type="type"
      :per-page="perPage"
      :add-component-name="addComponentName"
    >
      <template #cell(email_sended)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span v-if="data.item.email_sended === 1">
              <feather-icon
                icon="CheckIcon"
                size="25"
                color="#02111b"
              />
            </span>

            <span v-else>
              <feather-icon
                icon="XIcon"
                size="25"
                color="red"
              />
            </span>
          </div>
        </slot>
      </template>
      <template #cell(require_audit)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span v-if="data.item.require_audit === 1">
              <feather-icon
                icon="CheckIcon"
                size="25"
                color="#02111b"
              />
            </span>

            <span v-else><feather-icon
              icon="XIcon"
              size="25"
              color="red"
            /></span>
          </div>
        </slot>
      </template>
      <template #cell(invoice)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span>
              <feather-icon
                v-b-popover.hover.topright="'show invoice'"
                icon="FileTextIcon"
                size="25"
                color="#02111b"
                style="cursor: pointer;"
                @click="
                  $router.push({
                    name: 'show-invoice',
                    params: { id: data.item.id }
                  })
                "
              />
            </span>
          </div>
        </slot>
      </template>
      <template #cell(certificate)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span
              style="font-size: smaller; text-wrap: nowrap"
            >
              {{ data.item.certificate }}
            </span>
          </div>
        </slot>
      </template>
      <template #cell(sub_certificate)="data">
        <div v-if="role === 'super-accountant' || role === 'accountant'">  <span
          style="font-size: smaller; text-wrap: nowrap"
        >
          {{ data.item.sub_certificate }}
        </span></div>
        <div v-else>
          <div
            v-if="data.item.sub_certificate === null"
            v-b-popover.hover.topright="'edit sub certificate'"
            style="width: 100% !important; height: 24px !important"
            class="hover-pointer-empty text-center"
            @click="
              $router.push({
                name: 'edit-sub-certificate',
                params: { id: data.item.id }
              })
            "
          />
          <div
            v-b-popover.hover.topright="'edit sub certificate'"
            style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
            class="hover-pointer text-center"
            @click="
              $router.push({
                name: 'edit-sub-certificate',
                params: { id: data.item.id }
              })
            "
          >
            <span
              style="font-size: smaller; text-wrap: nowrap"
            >
              {{ data.item.sub_certificate }}
            </span>
          </div>
        </div>
      </template>
      <template #cell(facility_name)="data">
        <div
          v-if="data.item.facility_name === ''"
          v-b-popover.hover.topright="'show facility info'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'request-histories',
              params: { id: data.item.id }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'show facility info'"
          class="hover-pointer text-center "
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
          @click="
            $router.push({
              name: 'request-histories',
              params: { id: data.item.id }
            })
          "
        >
          <span
            style="font-size: smaller;"
            class="text-nowrap"
          >
            {{ data.item.facility_name }}
          </span>
        </div>
      </template>
      <template #cell(payment_status)="data">
        <div v-if="role === 'process-manager'">
          <span
            style="font-size: smaller;"
            class="text-nowrap"
          >
            {{ data.item.payment_status }}
          </span>
        </div>
        <div v-else>
          <div
            v-b-popover.hover.topright="'+ add payment'"
            class="hover-pointer text-center "
            style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
            @click="
              $router.push({
                name: 'edit-payment-status',
                params: { id: data.item.id }
              })
            "
          >
            <span
              style="font-size: smaller;"
              class="text-nowrap"
            >
              {{ data.item.payment_status }}
            </span>
          </div>
        </div>
      </template>
      <!-- Sticky Cell for Request Code -->
      <template #cell(request_code)="data">
        <div
          v-b-popover.hover.topright="'show request info'"
          @click="
            $router.push({
              name: 'request-histories',
              params: { id: data.item.id }
            })
          "
        >
          <span
            style="font-size: smaller; text-wrap: nowrap"
          >
            {{ data.item.request_code }}
          </span>
        </div>
      </template>
      <!-- ----- test  -->
      <template #cell(offer)="data">
        <div
          v-if="data.item.offer === null"
          v-b-popover.hover.topright="'+ add offer'"
          style="width: 100% !important; height: 24px !important;font-size: smaller;"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'set-offer',
              params: { id: data.item.id }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'+ add offer'"
          class="hover-pointer text-center"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;font-size: smaller;"
          @click="
            $router.push({
              name: 'set-offer',
              params: { id: data.item.id }
            })
          "
        >

          <span v-if="data.item.offer">
            {{ data.item.offer.name }} - {{ data.item.offer.amount }} %
          </span>
        </div>
      </template>

      <template #cell(office)="data">
        <div
          v-if="data.item.office === null"
          v-b-popover.hover.topright="'+ add office'"
          style="width: 100% !important; height: 24px !important;font-size: smaller;"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'set-office',
              params: { id: data.item.id }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'+ add office'"
          class="hover-pointer text-center"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;font-size: smaller;"
          @click="
            $router.push({
              name: 'set-office',
              params: { id: data.item.id }
            })
          "
        >

          <span v-if="data.item.office">
            {{ data.item.office }}
          </span>
        </div>
      </template>

      <template #cell(first_reviewer)="data">
        <div
          v-if="data.item.first_reviewer === null"
          v-b-popover.hover.topright="'+ add first reviewer'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id , type: 'first_reviewer'}
            })
          "
        />

        <div
          v-b-popover.hover.topright="'+ add first reviewer'"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
          class="hover-pointer text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id , type: 'first_reviewer'}
            })
          "
        >
          <span
            style="font-size: smaller; text-wrap: nowrap"
          >
            {{ data.item.first_reviewer }}
          </span>
        </div>
      </template>

      <template #cell(second_reviewer)="data">
        <div
          v-if="data.item.second_reviewer === null"
          v-b-popover.hover.topright="'+ add second reviewer'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id , type: 'second_reviewer'}
            })
          "
        />
        <div
          v-b-popover.hover.topright="'+ add second reviewer'"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
          class="hover-pointer text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id , type: 'second_reviewer'}
            })
          "
        >
          <span
            style="font-size: smaller; text-wrap: nowrap"
          >
            {{ data.item.second_reviewer }}
          </span>
        </div>
      </template>

      <template #cell(application_reviewer)="data">
        <div
          v-if="data.item.application_reviewer === null"
          v-b-popover.hover.topright="'+ add application reviewer'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id , type: 'application_reviewer'}
            })
          "
        />
        <div
          v-b-popover.hover.topright="'+ add application reviewer'"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
          class="hover-pointer text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id , type: 'application_reviewer'}
            })
          "
        >
          <span
            style="font-size: smaller; text-wrap: nowrap"
          >
            {{ data.item.application_reviewer }}
          </span>
        </div>
      </template>
      <template #cell(requester)="data">
        <span
          v-if="data.item.delegator"
          style="font-size: smaller; text-wrap: nowrap"
        >
          {{ data.item.delegator.name }}
        </span>

      </template>
    </general-table>
  </div>
</template>

<script>
// import { title } from '@/@core/utils/filter'
// import { mapState } from 'vuex'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'requests',
      viewContent: true,
      type: 'page',
      perPage: 50,
      filter: {},
      addType: 'Add Request',
      addComponentName: 'add-request',
      columns: [
        { key: 'id', sortable: false },
        {
          key: 'request_code', stickyColumn: true, isRowHeader: true, label: 'Request Code',
        },
        { key: 'facility_name', label: 'Facility Name' },
        { key: 'requester', label: 'Requester' },
        { key: 'payment_status', label: 'Payment Status' },
        { key: 'payment_method', label: 'P.Method' },
        { key: 'due_date', label: 'Due Date' },
        { key: 'source', label: 'Source' },
        { key: 'certificate', label: 'Certificate' },
        { key: 'sub_certificate', label: 'Sub Certificate' },
        { key: 'location', label: 'Country' },
        { key: 'office', label: 'Payment Country' },
        { key: 'offer', label: 'Offer' },
        { key: 'certificate_price', label: 'Certificate Price' },
        { key: 'tax', label: 'Tax' },
        { key: 'other_tax', label: 'Other Tax' },
        { key: 'final_price', label: 'Final Price' },
        { key: 'wallet_balance', label: 'Wallet Balance' },
        // { key: 'edit_payment_status', label: 'Edit Payment Status' },
        { key: 'first_reviewer', label: 'first reviewer' },
        { key: 'second_reviewer', label: 'second reviewer' },
        { key: 'application_reviewer', label: 'application reviewer' },
        { key: 'require_audit', label: 'Require Audit' },
        { key: 'email_sended', label: 'Email Sended' },

        // { key: 'set_offer', label: 'Set Offer' },
        // { key: 'assign_engineer', label: 'Assign Engineer' },
        { key: 'invoice', label: 'Invoice' },
      ],

      role: localStorage.getItem('accessRole'),
    }
  },
  computed: {
    filteredColumns() {
      if (this.role === 'super-accountant' || this.role === 'accountant') {
        return this.columns.filter(column => [
          'id',
          'request_code',
          'payment_status',
          'payment_method',
          'source',
          'certificate',
          'sub_certificate',
          'office',
          'offer',
          'location',
          'created_at',
          'final_price',
          'certificate_price',
          'wallet_balance',
          'facility_name',
          'facility',
          'invoice',
          'edit_payment_status',
          'requester',
        ].includes(column.key))
      }
      if (this.role === 'process-manager') {
        return this.columns.filter(column => [
          'id',
          'request_code',
          'payment_status',
          'first_reviewer',
          'second_reviewer',
          'application_reviewer',
          'created_at',
          'source',
          'location',
          'certificate',
          'sub_certificate',
          'require_audit',
          'assign_engineer',
          'requester',
        ].includes(column.key))
      }
      if (this.role === 'markter') {
        return this.columns.filter(column => [
          'id',
          'request_code',
          'payment_status',
          'payment_method',
          'location',
          'due_date',
          'source',
          'certificate',
          'sub_certificate',
          'offer',
          'created_at',
          'facility_name',
          'require_audit',
          'email_sended',
          'set_offer',
          'requester',

        ].includes(column.key))
      }
      return this.columns
    },
    apiUrl() {
      const base = 'requests?'
      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      const queryParams = Object.entries(this.filter)
        .filter(([key, value]) => key && value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      return `${base}${queryParams}`
    },
  },
  methods: {
    filterTypes(type) {
      this.types = this.status.filter(item => item.text !== type)
    },
  },
}
</script>

<style lang="scss" scoped>
.hover-pointer:hover {
  color: white; /* Change the property you want to affect on hover */
  background-color: #40aaf7;
  cursor: pointer;
  padding: 8.5px 0px;
}
.hover-pointer-empty:hover {
  color: white; /* Change the property you want to affect on hover */
  background-color: #40aaf7;
  cursor: pointer;
  padding: 14.5px 0px;
}

</style>
